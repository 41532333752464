import React from 'react'
import LandingPageAdvertorial from '../../templates/landing-page-advertorial'

import content from '../../data/pages/article/seek-new-funding/content.json'

const SeekNewFundingLP = () => (
  <LandingPageAdvertorial content={content} />
)

export default SeekNewFundingLP
