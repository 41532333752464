import React from 'react'

import Title from '../atoms/title'
import Link from '../atoms/link'
import Button from '../atoms/button'
import BodyCopy from '../atoms/body-copy'
import Lists from '../atoms/lists'


const Advertorial = ({
  content
}) => {
  return (
    <div className='co-advertorial co-advertorial--info'>
      <div className='l-section'>
        <div className='co-advertorial__info'>
          <span className='co-advertorial__type'>{content.section1.info.type}</span>
          <span className='co-advertorial__category'>{content.section1.info.category}</span><span className='co-advertorial__divider'>|</span><span className='co-advertorial__date'>{content.section1.info.date}</span>
        </div>
        <Title
            type='h1'
            title={content.section1.titleLevel1}
            classNames='co-advertorial__title'
        />
        <Link 
          url={content.section1.image.linkUrl}
          align='center'
          classNames='co-advertorial__image l-spacing__m-tb--40'
          type="image"
        >
          <img src={require(`../../images/${content.section1.image.src}`)} alt={content.section1.image.alt} />
        </Link>
        <Title
            type='h5'
            title={content.section1.titleLevel3}
            align='left'
            color='dark'
            weight='bold'
            classNames='co-advertorial__title'
        />
        <BodyCopy
          copy={content.section1.bodyCopy}
          classNames='co-advertorial__copy'
        />
        <Lists
          items={content.section1.list}
          classNames='co-advertorial__list'
        />
        <BodyCopy
          copy={content.section1.bodyCopy2}
          classNames='co-advertorial__copy'
        />
      </div>

      <div className='l-section'>
        <Title
          type='h3'
          title={content.section2.titleLevel2}
          align='left'
          color='dark'
          weight='bold'
          classNames='co-advertorial__title'
        />

        <BodyCopy
          copy={content.section2.bodyCopy}
          classNames='co-advertorial__copy'
        />
      </div>


      <div className='l-section'>
        <Title
          type='h3'
          title={content.section3.titleLevel2}
          align='left'
          color='dark'
          weight='bold'
          classNames='co-advertorial__title'
        />

        <Lists
          items={content.section3.list}
          classNames='co-advertorial__list'
        />

        <Link
          url={content.section3.image.linkUrl}
          align='center'
          classNames='co-advertorial__image l-spacing__m-tb--40'
          type="image"
        >
          <img src={require(`../../images/${content.section3.image.src}`)} alt={content.section3.image.alt} />
        </Link>
      </div>

    </div>
  )
}

export default Advertorial
